import { ApiId, AppRecord, Many2Many, Many2One } from "./api.model";

export type DiveOperationState =
  | "draft"
  | "confirmed"
  | "started"
  | "done"
  | "cancel";

export class DiveOperationCommon extends AppRecord {
  public operation_id: ApiId;

  public date_start: string;
  public job_id: Many2One;
  public partner_id: Many2One;
  public site_id: Many2One;
  public supervisor_id: Many2One;
  public diver_ids: Many2Many<Many2One>;
  public state: DiveOperationState;
  public state_tittle: string;
  public date: Date;

  constructor(data: Partial<DiveOperationCommon> = {}) {
    super(data);

    this.operation_id = data.operation_id;
    this.date_start = data.date_start;
    this.job_id = data.job_id ? new Many2One(data.job_id) : null;
    this.partner_id = data.partner_id ? new Many2One(data.partner_id) : null;
    this.site_id = data.site_id ? new Many2One(data.site_id) : null;
    this.supervisor_id = data.supervisor_id
      ? new Many2One(data.supervisor_id)
      : null;

    this.diver_ids = new Many2Many(
      data.diver_ids ? data.diver_ids.map((diver) => new Many2One(diver)) : []
    );

    this.state = data.state;

    this.date = this.date_start ? new Date(this.date_start) : new Date();
  }
}
