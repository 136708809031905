import { ApiId } from "@app/models";
import { Boxed, Unboxed } from "ngrx-forms";
import {
  AppRecord,
  CommonApiValue,
  CommonFormValue,
  IMany2One,
  IOne2Many,
  ITemplateTripeItemForm,
  Many2One,
  One2Many,
  TemplateTripeItem,
} from "./api.model";
import { DiveOperationCommon } from "./common.model";
import { ITimesheetForm, Timesheet } from "./timesheet.models";

export const DIVE_LOG_MODEL = "dive.log";

export const DIVE_LOG_LINE_MODEL = "dive.log.line";

export const DIVE_LOG_TEMPLATE_MODEL = "dive.log.template";

export interface IDiveLogGroupForm extends IMany2One {
  state: "supervisor" | "pre_dive" | "dive";
}

export class DiveLogGroup extends Many2One implements IDiveLogGroupForm {
  public state: "supervisor" | "pre_dive" | "dive";

  constructor(data: DiveLogGroup) {
    super(data);

    this.state = data.state;
  }
}

export interface IDiveLogItemForm extends ITemplateTripeItemForm {
  group_id: IDiveLogGroupForm;
  is_activity: boolean;
  time_sheet_1_id: ITimesheetForm;
  time_sheet_2_id: ITimesheetForm;
  time_sheet_3_id: ITimesheetForm;
}

export interface IDiveLogLineForm {
  log_id: ApiId;

  template_id: Boxed<IMany2One>;

  signature: string;

  diver_1_id: Boxed<IMany2One>;
  diver_2_id: Boxed<IMany2One>;
  standby_id: Boxed<IMany2One>;

  diver_1_signature_pre: string;
  diver_2_signature_pre: string;
  standby_signature_pre: string;

  diver_1_signature_post: string;
  diver_2_signature_post: string;
  standby_signature_post: string;

  item_ids: IOne2Many<IDiveLogItemForm>;
  time_sheet_ids: IOne2Many<ITimesheetForm>;
}

export interface IDiveLogForm {
  operation_id: ApiId;
}

export class DiveLogLineFormValue
  extends CommonFormValue<DiveLogLineFormValue>
  implements IDiveLogLineForm
{
  public log_id: ApiId;

  public template_id: Boxed<IMany2One>;

  public signature: string;

  public diver_1_id: Boxed<IMany2One>;
  public diver_2_id: Boxed<IMany2One>;
  public standby_id: Boxed<IMany2One>;

  public diver_1_signature_pre: string;
  public diver_2_signature_pre: string;
  public standby_signature_pre: string;

  public diver_1_signature_post: string;
  public diver_2_signature_post: string;
  public standby_signature_post: string;

  public item_ids: IOne2Many<IDiveLogItemForm>;
  public time_sheet_ids: IOne2Many<ITimesheetForm>;

  constructor(data: Partial<Unboxed<IDiveLogLineForm>> = {}) {
    super();

    this.setProperty("log_id", data);

    this.setMany2One("template_id", data);

    this.setProperty("signature", data);

    this.setMany2One("diver_1_id", data);
    this.setMany2One("diver_2_id", data);
    this.setMany2One("standby_id", data);

    this.setProperty("diver_1_signature_pre", data);
    this.setProperty("diver_2_signature_pre", data);
    this.setProperty("standby_signature_pre", data);

    this.setProperty("diver_1_signature_post", data);
    this.setProperty("diver_2_signature_post", data);
    this.setProperty("standby_signature_post", data);

    this.setOne2Many("item_ids", data);
    this.setOne2Many("time_sheet_ids", data);
  }
}

export class DiveLogLineApiValue
  extends CommonApiValue<DiveLogLineApiValue>
  implements Unboxed<IDiveLogLineForm>
{
  public log_id: ApiId;

  public template_id: Many2One;

  public signature: string;

  public diver_1_id: Many2One;
  public diver_2_id: Many2One;
  public standby_id: Many2One;

  public diver_1_signature_pre: string;
  public diver_2_signature_pre: string;
  public standby_signature_pre: string;

  public diver_1_signature_post: string;
  public diver_2_signature_post: string;
  public standby_signature_post: string;

  public item_ids: One2Many<DiveLogItem>;
  public time_sheet_ids: One2Many<Timesheet>;

  constructor(data: Partial<IDiveLogLineForm> = {}) {
    super();

    this.setProperty("log_id", data);

    this.setMany2One("template_id", data);

    this.setProperty("signature", data);

    this.setMany2One("diver_1_id", data);
    this.setMany2One("diver_2_id", data);
    this.setMany2One("standby_id", data);

    this.setProperty("diver_1_signature_pre", data);
    this.setProperty("diver_2_signature_pre", data);
    this.setProperty("standby_signature_pre", data);

    this.setProperty("diver_1_signature_post", data);
    this.setProperty("diver_2_signature_post", data);
    this.setProperty("standby_signature_post", data);

    this.setOne2Many("item_ids", data);
    this.setOne2Many("time_sheet_ids", data);
  }
}

export class DiveLogItem extends TemplateTripeItem implements IDiveLogItemForm {
  public group_id: DiveLogGroup;
  public is_activity: boolean;
  public time_sheet_1_id: Timesheet;
  public time_sheet_2_id: Timesheet;
  public time_sheet_3_id: Timesheet;
  constructor(data: Partial<DiveLogItem> = {}, isTemplateItem = false) {
    super(data, isTemplateItem);

    this.group_id = new DiveLogGroup(data.group_id);
    this.is_activity = data.is_activity;
    this.time_sheet_1_id = data.time_sheet_1_id
      ? new Timesheet(data.time_sheet_1_id)
      : null;
    this.time_sheet_2_id = data.time_sheet_2_id
      ? new Timesheet(data.time_sheet_2_id)
      : null;
    this.time_sheet_3_id = data.time_sheet_3_id
      ? new Timesheet(data.time_sheet_3_id)
      : null;
  }
}

export class DiveLogLine
  extends DiveOperationCommon
  implements Unboxed<IDiveLogLineForm>
{
  public log_id: ApiId;
  public template_id: Many2One;

  public signature: string;

  public diver_1_id: Many2One;
  public diver_2_id: Many2One;
  public standby_id: Many2One;

  public diver_1_signature_pre: string;
  public diver_2_signature_pre: string;
  public standby_signature_pre: string;

  public diver_1_signature_post: string;
  public diver_2_signature_post: string;
  public standby_signature_post: string;

  public item_ids: One2Many<DiveLogItem>;
  public time_sheet_ids: One2Many<Timesheet>;

  constructor(data: Partial<DiveLogLine> = {}) {
    super(data);

    this.log_id = data.log_id;

    this.template_id = data.template_id ? new Many2One(data.template_id) : null;

    // Quarantine until making sure it does not needed.
    // this.date = data.date ? new Date(data.date) : null;

    this.signature = data.signature ?? null;

    this.diver_1_id = data.diver_1_id ? new Many2One(data.diver_1_id) : null;
    this.diver_2_id = data.diver_2_id ? new Many2One(data.diver_2_id) : null;
    this.standby_id = data.standby_id ? new Many2One(data.standby_id) : null;

    this.diver_1_signature_pre = data.diver_1_signature_pre;
    this.diver_2_signature_pre = data.diver_2_signature_pre;
    this.standby_signature_pre = data.standby_signature_pre;

    this.diver_1_signature_post = data.diver_1_signature_post;
    this.diver_2_signature_post = data.diver_2_signature_post;
    this.standby_signature_post = data.standby_signature_post;

    this.item_ids = new One2Many(
      data.item_ids ? data.item_ids.map((item) => new DiveLogItem(item)) : []
    );

    this.time_sheet_ids = new One2Many(
      data.time_sheet_ids
        ? data.time_sheet_ids
            .filter((timesheet) => !timesheet.is_auto)
            .map((timesheet) => new Timesheet(timesheet))
        : []
    );
  }
}

export class DiveLog extends DiveOperationCommon implements IDiveLogForm {}

export class DiveLogTemplate
  extends AppRecord
  implements Partial<Unboxed<IDiveLogLineForm>>
{
  public item_ids: One2Many<DiveLogItem>;

  constructor(data: Partial<DiveLogTemplate> = {}) {
    super(data);

    this.item_ids = new One2Many(
      data.item_ids
        ? data.item_ids.map((item) => new DiveLogItem(item, true))
        : []
    );
  }
}

export interface HideOptions {
  hideDiver1: boolean;
  hideDiver2: boolean;
  hideStandby: boolean;
}
