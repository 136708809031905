import {
  ApiId,
  AppRecord,
  CommonApiValue,
  CommonFormValue,
  IMany2One,
  IOne2Many,
  IOne2ManyItem,
  ITemplateSingleItem,
  Many2One,
  One2Many,
  One2ManyItem,
  TemplateSingleItem,
} from "@app/models";
import { Boxed, Unboxed } from "ngrx-forms";
import { DiveOperationCommon } from "./common.model";

export const TOOLBOX_TALK_MODEL = "toolbox.talk";

export const TOOLBOX_TALK_TEMPLATE_MODEL = "toolbox.talk.template";

export interface IToolboxTalkItemForm extends ITemplateSingleItem {
  group_id: IMany2One;
}

export interface IToolboxTalkAttendeeForm extends IOne2ManyItem {
  position: string;
  signature: any;
}

export interface IToolboxTalkForm {
  operation_id?: ApiId;
  template_id: Boxed<IMany2One>;
  speaker_name: string;
  speaker_position: string;
  signature: string;
  item_ids: IOne2Many<IToolboxTalkItemForm>;
  attendee_ids: IOne2Many<IToolboxTalkAttendeeForm>;
}

export interface IToolboxTalkGroup extends IMany2One {
  item_ids: IOne2Many<IToolboxTalkItemForm>;
}

export class ToolboxTalkAttendee
  extends One2ManyItem
  implements IToolboxTalkAttendeeForm
{
  public position: string;
  public signature: any;
  constructor(data: Partial<ToolboxTalkAttendee> = {}) {
    super(data);

    if (!this.hasOwnProperty("name")) {
      this.name = null;
    }

    this.position = data.position;
    this.signature = data.signature;
  }
}

export class ToolboxTalkItem
  extends TemplateSingleItem
  implements IToolboxTalkItemForm
{
  group_id: Many2One;

  constructor(data: Partial<ToolboxTalkItem> = {}, isTemplateItem = true) {
    super(data, isTemplateItem);
    this.group_id = new Many2One(data.group_id);
  }
}

export class ToolboxTalk
  extends DiveOperationCommon
  implements Unboxed<IToolboxTalkForm>
{
  public template_id: Many2One;
  public speaker_name: string;
  public speaker_position: string;
  public signature: string;
  public item_ids: One2Many<ToolboxTalkItem>;
  public attendee_ids: One2Many<ToolboxTalkAttendee>;

  constructor(data: Partial<ToolboxTalk> = {}) {
    super(data);

    this.template_id = data.template_id ? new Many2One(data.template_id) : null;
    this.speaker_name = data.speaker_name || null;
    this.speaker_position = data.speaker_position || null;
    this.signature = data.signature || null;

    this.item_ids = new One2Many<ToolboxTalkItem>(
      data.item_ids
        ? data.item_ids.map((item) => new ToolboxTalkItem(item))
        : []
    );

    this.attendee_ids = new One2Many(
      data.attendee_ids
        ? data.attendee_ids.map((attendee) => new ToolboxTalkAttendee(attendee))
        : []
    );
  }
}

export class ToolboxTalkTemplate
  extends AppRecord
  implements Partial<Unboxed<IToolboxTalkForm>>
{
  public item_ids: One2Many<ToolboxTalkItem>;

  constructor(data: Partial<ToolboxTalkTemplate> = {}) {
    super(data);

    this.item_ids = new One2Many(
      data.item_ids
        ? data.item_ids.map((item) => new ToolboxTalkItem(item, true))
        : []
    );
  }
}

export class ToolboxTalkFormValue
  extends CommonFormValue<ToolboxTalkFormValue>
  implements IToolboxTalkForm
{
  public template_id: Boxed<IMany2One>;
  public speaker_name: string;
  public speaker_position: string;
  public signature: string;
  public item_ids: IOne2Many<IToolboxTalkItemForm>;
  public attendee_ids: IOne2Many<IToolboxTalkAttendeeForm>;

  constructor(data: Partial<Unboxed<IToolboxTalkForm>> = {}) {
    super();

    this.setMany2One("template_id", data);
    this.setProperty("speaker_name", data);
    this.setProperty("speaker_position", data);
    this.setProperty("signature", data);
    this.setOne2Many("item_ids", data);
    this.setOne2Many("attendee_ids", data);
  }
}

export class ToolboxTalkApiValue
  extends CommonApiValue<ToolboxTalkApiValue>
  implements Unboxed<IToolboxTalkForm>
{
  public operation_id: ApiId;
  public template_id: Many2One;
  public speaker_name: string;
  public speaker_position: string;
  public signature: string;
  public item_ids: One2Many<ToolboxTalkItem>;
  public attendee_ids: One2Many<ToolboxTalkAttendee>;

  constructor(data: Partial<IToolboxTalkForm> = {}) {
    super();

    this.setProperty("operation_id", data);
    this.setMany2One("template_id", data);
    this.setProperty("speaker_name", data);
    this.setProperty("speaker_position", data);
    this.setProperty("signature", data);
    this.setOne2Many("item_ids", data);
    this.setOne2Many("attendee_ids", data);
  }
}
